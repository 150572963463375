<template>
    <v-card color="#F6F9FC" flat class="rounded-0 pt-6">
        <v-container>
            <v-row>
                <v-col cols="12" lg="3">
                    <DashboardSidebar></DashboardSidebar>
                </v-col>
                <v-col cols="12" lg="9">
                    <v-card flat class="pa-4">
                        <v-row align="center">
                            <v-col cols="12" lg="2" md="2">
                                <p class="mb-0 text-h6 font-weight-medium">{{ $t('提现记录') }}</p>
                            </v-col>
                            <v-col cols="12" lg="10" md="10">
                                <v-row>
                                    <v-col cols="12" lg="7" md="7">
                                        <div class="d-flex align-center justify-end">
                                            <span>{{ $t('类型') }}：</span>

                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="5" md="5">
                                        <div class="d-flex align-center">

                                            <v-card height="40" flat class="mr-2">
                                                <v-text-field outlined dense :placeholder="$t('模版名称')"></v-text-field>
                                            </v-card>
                                            <v-btn color="primary" height="40"
                                                depressed><v-icon>mdi-magnify</v-icon></v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                                <div class="d-flex justify-space-between align-center">



                                </div>
                            </v-col>

                        </v-row>

                        <v-divider class="my-4"></v-divider>
                        <div>
                            <v-card color="#F2F2F2" class=" rounded-0 px-6 py-3" flat>
                                <v-row>
                                    <v-col cols="3">时间</v-col>
                                    <v-col cols="3">类型</v-col>
                                    <v-col cols="3">详情</v-col>
                                    <v-col cols="3" class="text-right">金额</v-col>
                                </v-row>

                            </v-card>
                            <v-card flat v-for="n in 3" :key="n" class="px-6 py-3" :color="n%2!=0?'white':'#F7F7F7'">
                                <v-row>
                                    <v-col cols="3">2024/01/04</v-col>
                                    <v-col cols="3">收益</v-col>
                                    <v-col cols="3">有人买我模版</v-col>
                                    <v-col cols="3" class="text-right warning--text">￥100</v-col>
                                </v-row>






                            </v-card>
                        </div>
                    </v-card>



                </v-col>
            </v-row>
        </v-container>

    </v-card>
</template>
<script>
import DashboardSidebar from '../../components/DashboardSidebar.vue'
export default {
    components: {
        DashboardSidebar
    },
    data() {
        return {


        }
    }
}
</script>
